import React from 'react'
const NewsBaseURL = "blog"
const CommuntiesBaseURL = "purpose/communities"
const AreaBaseURL = '/area-guides'
const OfficeBaseURL = '/contact'
const CaseStudyBaseURL = 'property-services/new-homes/case-studies'
const BookaValuationURL   = '/property-services/sell/property-valuation'

export {
    NewsBaseURL,
    AreaBaseURL,
    CaseStudyBaseURL,
    OfficeBaseURL,
    BookaValuationURL,
    CommuntiesBaseURL
}

export const scrollToElement = (element, offset = 1) => {
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset

  typeof window !== "undefined" &&
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    })
}
