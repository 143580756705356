import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark, Card, Tabs, Tab, } from "react-bootstrap";
import "./ValuationStepsBanner.scss";
import ReactMarkdown from "react-markdown/with-html";

const ValuationStepsBanner = (props) => {
  return (
    <React.Fragment>
      
     <Container className="container-banner">
        <Row>
          <Col lg={12}>
          <ScrollAnimation duration={0.5} offset={0} initiallyVisible={true} animateOnce={true} animateIn="fadeInUp">
              {
                props.valtype ? (
                  <h4 className="small-heading back_btn" onClick={props.handleBack}>&lt; Back</h4>
                ) : (
                  <h4 className="small-heading">{props.Title}</h4>
                )
              }
              
              <h1>{props.Heading}</h1>
              <div className="banner-p-content">
                <ReactMarkdown source={props.Content} escapeHtml={false}/>
              </div>
            </ScrollAnimation>

          </Col>
        </Row>
      </Container> 
            
      
    
    </React.Fragment>
  );
};
export default ValuationStepsBanner;
