export const defaultValues = {
  PROPERTY_VALUE: 900000,
  DEPOSIT: 225000,
  DEPOSIPROP: 11250,
  DURATION: "25",
  INTEREST: "2",
  DEFAULT_RESULT: false,
  INSTANT_RESULT: false,
  CURRENCY: "£",
  PRICE_PREFIX: true,
  DURATION_SUFFIX: true,
  YEAR_SUFFIX: true,
  YEAR_SELECT: true,
}
